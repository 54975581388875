import * as React from 'react';
import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Box,
  styled,
  useTheme
} from '@mui/material';

import { ReactComponent as CookingIcon } from '../../../../assets/ic_cooking.svg';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../../../../redux/shopDrawer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InvoiceIcon from '@mui/icons-material/Receipt';
import { useAppSelector } from 'redux/hook';
import { Feedback } from '@mui/icons-material';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean }>(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: `${drawerWidth}px`
    })
  })
);

type Props = { children: React.ReactNode };

export const ShopAdminDrawer = ({ children }: Props) => {
  const { isOpen } = useAppSelector((s) => s.shopAdminDrawer);
  const { user } = useAppSelector((s) => s.user);

  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerMenu = [
    {
      items: [
        {
          text: 'Inicio',
          icon: <DashboardIcon />,
          onClick: () => navigate('/Inicio')
        }
      ]
    },
    {
      text: 'Restaurante',
      items: [
        {
          text: 'En Proceso',
          icon: <CookingIcon style={{ fill: palette.grey[500] }} />,
          onClick: () => navigate('/en_proceso')
        },
        {
          text: 'Ordenes',
          icon: <ReceiptLongIcon />,
          onClick: () => navigate('/ordenes')
        },
        {
          text: 'Feedbacks',
          icon: <Feedback />,
          onClick: () => navigate('/feedbacks')
        }
      ]
    },
    {
      text: 'Administrar',
      items: [
        {
          text: 'Tienda',
          icon: <StoreIcon />,
          onClick: () => navigate('/admin/tienda')
        },
        {
          text: 'Menu',
          icon: <MenuBookIcon />,
          onClick: () => navigate('/admin/menu')
        },
        {
          text: 'Personal',
          icon: <PeopleIcon />,
          onClick: () => navigate('/admin/personal')
        }
      ]
    },
    {
      text: 'Facturación',
      items: [
        {
          text: 'Facturas generadas',
          icon: <InvoiceIcon />,
          onClick: () => navigate('/admin/facturas-generadas')
        }
      ]
    }
  ];

  const shop = user?.branchWork.branch;

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor={'left'}
        open={isOpen}
        onClose={() => dispatch(toggleDrawer())}>
        <DrawerHeader>
          <Avatar src={shop?.profileImage} />
          <ListItemText primary={shop?.name} sx={{ ml: 2 }} />
          <IconButton onClick={() => dispatch(toggleDrawer())}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        {drawerMenu.map(({ text, items }, i) => (
          <Box key={i}>
            <Typography color={palette.grey[600]} fontSize={14} padding={1} ml={1}>
              {text}
            </Typography>
            <Divider />
            <List>
              {items.map(({ icon, onClick, text }) => {
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton onClick={onClick}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        ))}
      </Drawer>
      <Main open={isOpen}>{children}</Main>
    </>
  );
};
